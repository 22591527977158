import { AuthService } from 'src/app/dashboard/services/auth.service';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse
} from '@angular/common/http';
import { map, catchError, finalize, timeout, switchMap } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { LoadingService } from './loading.service';
import { AlertType } from '../components/alert/alert.component';
import { environment } from 'src/environments/environment';
import { UtilService } from './util.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private loading: LoadingService,
    private utilService: UtilService,
    private authService: AuthService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //https://scotch.io/@vigneshsithirai/angular-6-7-http-client-interceptor-with-error-handling
    //https://medium.com/@johnmeguira/intercept-all-http-calls-with-angular-5-to-display-a-loader-281924b73ad8
    //https://medium.com/@zeljkoradic/loader-bar-on-every-http-request-in-angular-6-60d8572a21a9

    if (!req.headers.get('Content-Type') && !(req.body instanceof FormData))
      req = req.clone({
        headers: req.headers.append(
          'Content-Type',
          'application/json; charset=utf-8'
        )
      });

    const appKey = environment.appKey;
    if (appKey)
      req = req.clone({ headers: req.headers.append('X-APP-KEY', appKey) });

    let showLoading = true;
    if (req.headers.has('showLoading')) {
      showLoading =
        req.headers.get('showLoading')!.toLowerCase() == 'false' ? false : true; //añadir header '"showLoading": "false"' para evitar mostrar el spiner
      req.headers.delete('showLoading');
    }

    let type = 'default';
    if (req.headers.has('typeLoading')) {
      type = req.headers.get('typeLoading')!.toLowerCase() || 'default';
      req.headers.delete('typeLoading');
    }

    if (showLoading) {
      this.loading.showLoader(type);
    }

    req = req.clone({
      withCredentials: true
    });

    return next.handle(req).pipe(
      timeout(30000),
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((response) => {
        if (response instanceof HttpErrorResponse && response.status === 401) {
          return this.handle401Error(req, next);
        } else {
          const messages =
            response?.error?.message ||
            this.TryJsonParse(response?.error)?.message ||
            response?.message ||
            'GENERICS.ERROR.TRY_LATER';

          if (Array.isArray(messages)) {
            messages.forEach((message: string, index: number) => {
              setTimeout(() => {
                this.utilService.alert(message, AlertType.danger);
              }, 100 * index);
            });
          } else {
            this.utilService.alert(messages, AlertType.danger);
          }
          throw response;
        }

        //throw response;
      }),
      finalize(() => {
        if (showLoading) {
          this.loading.hideLoader(type);
        }
      })
    );
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (request.url.includes('/refresh')) {
      this.goToLogin();
      return EMPTY;
    }
    return this.authService.refreshToken().pipe(
      switchMap((response: any) => {
        if (response._httpResponseCode === 200) {
          const newRequest = request.clone();
          return next.handle(newRequest);
        }
      })
    );
  }

  private TryJsonParse(json: string): any {
    try {
      return JSON.parse(json);
    } catch (e) {
      return null;
    }
  }

  private goToLogin() {
    this.router.navigateByUrl('/dashboard/login');
  }
}
