import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  //public isLoading = new Subject<boolean>();
  //private counterLoading: number = 0;
  //public showLoading: boolean = false;

  private counterLoading = {
    block: 0,
    default: 0
  };
  public showLoading = {
    block: false,
    default: false
  };

  constructor() {}

  showLoader(type = 'default') {
    setTimeout(() => {
      this.showLoading[type] = true;
      this.counterLoading[type]++;
      //this.isLoading.next(true);
    }, 0);
  }

  hideLoader(type = 'default') {
    setTimeout(() => {
      this.counterLoading[type]--;
      if (this.counterLoading[type] <= 0) {
        this.counterLoading[type] = 0;
        this.showLoading[type] = false;
        //this.isLoading.next(false);
      }
    }, 0);
  }
}
