import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EventBusService {
  private subject$ = new Subject();

  constructor() {}

  emit<T = any>(name: string, data?: T) {
    this.subject$.next({ name, data });
  }

  on<T = any>(eventName: string): Observable<T> {
    return this.subject$.pipe(
      filter((e: EventData) => e.name === eventName),
      map((e: EventData) => e.data)
    );
  }
}

interface EventData<T = any> {
  name: string;
  data: T;
}

export const AppEvents = {
  update: {
    language: 'language',
    merchant: 'merchant',
    offer: 'offer',
    offerTags: 'offerTags',
    profile: 'profile'
  },
  alert: 'alert'
};
