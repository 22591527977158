import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(private http: HttpClient) {}

  get<T = any>(uri: string, params?: any): Observable<T> {
    return this.http.get<T>(`${environment.apiUrl}/${uri}`, {
      params: params,
      headers: this.httpOptions.headers
    });
  }

  post<T = any>(uri: string, payload: any, params?: any): Observable<T> {
    return this.http.post<T>(`${environment.apiUrl}/${uri}`, payload, {
      params: params,
      headers: this.httpOptions.headers
    });
  }

  patch<T = any>(uri: string, payload: any, params?: any): Observable<T> {
    return this.http.patch<T>(`${environment.apiUrl}/${uri}`, payload, {
      params: params,
      headers: this.httpOptions.headers
    });
  }

  put<T = any>(uri: string, payload: any, params?: any): Observable<T> {
    return this.http.put<T>(`${environment.apiUrl}/${uri}`, payload, {
      params: params,
      headers: this.httpOptions.headers
    });
  }

  delete<T = any>(uri: string, params?: any): Observable<T> {
    return this.http.delete<T>(`${environment.apiUrl}/${uri}`, {
      params: params,
      headers: this.httpOptions.headers
    });
  }
}
