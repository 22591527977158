import { DatatableResponse } from 'src/app/dashboard/models/response/datatable-response.model';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DefaultResponse } from '../models/response/default-response.model';

export abstract class CrudService {
  protected readonly httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  protected constructor(protected _http: HttpClient) {}

  abstract get url(): string;

  protected _getPaginated<T = any>(
    currentPage = 0,
    pageSize = 10,
    sortBy: string = null,
    sortDesc = false,
    search: string = null,
    filter: string = null,
    url: string = this.url
  ): Observable<DatatableResponse<T>> {
    let params = new HttpParams();
    if (currentPage != undefined || currentPage != null)
      params = params.append('currentPage', currentPage.toString());
    if (pageSize) params = params.append('pageSize', pageSize.toString());
    if (sortBy) params = params.append('orderBy', sortBy);
    if (sortDesc != undefined || sortDesc != null)
      params = params.append('desc', sortDesc.toString());

    if (search) params = params.append('search', search);
    if (filter) params = params.append('filter', filter);
    const options = {
      headers: this.httpOptions.headers,
      params: params
    };
    return this._http
      .get<DatatableResponse<T>>(url, options)
      .pipe(catchError(this.errorHandler));
  }

  protected _getAll<T = any>(url: string = this.url): Observable<T> {
    return this._http
      .get<T>(`${url}`, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  protected _getId<T = any>(
    id: number,
    url: string = this.url
  ): Observable<DefaultResponse<T>> {
    return this._http
      .get<DefaultResponse<T>>(`${url}/${id}`, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  protected _create<T = any>(
    data: Object,
    url: string = this.url
  ): Observable<T> {
    if (typeof data !== 'object')
      throw new Error('creation data must be an object');
    return this._http
      .post<T>(url, data, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  protected _update<T = any>(data: any, url: string = this.url): Observable<T> {
    if (typeof data !== 'object')
      throw new Error('update data must be an object');
    return this._http
      .put<T>(url, data, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  protected _delete<T = any>(
    id: number,
    url: string = this.url
  ): Observable<T> {
    return this._http
      .delete<T>(`${url}/${id}`, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  protected errorHandler(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${
        error?.error?.status || error.status
      }\nMessage: ${error?.error?.message || error.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
}
